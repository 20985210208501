import React from "react";
import classNames from "classnames";
import img1 from "../../assets/bbLogo/bbLogo1.svg";
import img2 from "../../assets/bbLogo/bbLogo2.svg";

import { Scroll } from "../scroll";
import { container } from "./index";

const header = classNames(
  "font-acromBold",
  "text-gray",
  "text-4xl",
  "md:!text-6xl",
  "text-left",
  "lg:!text-right"
);
const imgContainer = classNames(
  "w-full",
  "flex",
  "flex-row",
  "justify-center",
  "items-start"
);

export const Intro = () => {
  return (
    <div className={classNames(container, "lg:!flex-row")}>
      <div
        className={classNames(
          "w-full",
          "h-1/3",
          "md:!h-full",
          "flex",
          "flex-col",
          "justify-center",
          "relative"
          // 'bg-accent',
          // 'md:!w-2/3'
        )}
      >
        <div className={classNames(header, "p-4", "lg:!ml-12", "lg:!h-1/2")}>
          DESIGNED TO TAKE YOUR STYLE TO THE NEXT LEVEL
        </div>
      </div>
      {/* <div className="md:!mt-10"> */}

      <Scroll />
      {/* </div> */}

      <div className={classNames(imgContainer, "p-4 mt-4")}>
        <img src={img1} alt="haircut" className={"h-24 mt-2 md:!h-28"} />
        <img
          src={img2}
          alt="haircut2"
          className={"w-52 mt-4 md:!w-64 md:!mt-12"}
        />
      </div>
    </div>
  );
};
