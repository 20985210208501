import classNames from "classnames";
import React from "react";
// import dashaImages from '../assets/work/dasha/index.js';
import { ArtistCard } from "./ArtistCard.js";
import { Footer } from "./Footer.js";


const container  = classNames(
    'h-full',
    'flex', 
    'flex-col',
    'items-center',
    'w-full',
    'bg-black',
    'text-gray',
    'max-w-full',
    'min-h-[700px]'
)

const imageContainer = classNames(
    'h-[26rem]',
    'md:!h-[34rem]',
    'flex flex-row',
    'mb-8',
    'mt-4',
    'overflow-y-hidden',
    'overflow-x-auto',
    'snap-mandatory snap-x ...',
    'scrollbar-hide'
);

const header = classNames(
    'font-acromBold',
    'text-gray',
    'text-2xl',
    'md:!text-4xl',
)

const content = classNames(
    'pr-4 pl-4',
    'flex',
    'flex-col',
    'h-full',
    'w-full',
    'md:!w-11/12',
    'max-w-fit',
    'xl:!w-[90%]',
    '2xl:!w-[70%]',
    'justify-center',
)

const footerContainer= classNames(
    'h-[20%]',
    'bottom-0 left-0 right-0',
    'w-full',
    'bg-black'
   
)

export const ArtistWork =({images, artistName})=>{
    return(
        <div className={container}> 
            <div className={content}>
                <div className={classNames(header,'mt-2 mb-2 md:!mt-12 md:!mb-6')}>
                    {`${artistName}'s work`}
                </div>
                <div className= {classNames( imageContainer)}>
                        {images.map((el, index)=>
                            <ArtistCard artist ={{id:index, img:el}} key={index}/>
                        )}
                </div>
           
                <div className={footerContainer}>
                    <Footer/>
                </div>
            </div>
        </div>
    )
}