import React from "react";
import classNames from "classnames";
import { Links } from "./Links";
import { ArtistScroll } from "./ArtistScroll";

const container = classNames(
  "flex",
  "flex-col",
  "items-center",
  // 'justify-around',
  "md:!justify-start",
  "w-full",
  "bg-black",
  "min-h-[700px]",
  "md:!min-h-[500px]",
  "h-full"
);

const content = classNames(
  "pr-4 pl-4",
  "flex",
  "flex-col",
  "justify-center",
  "h-[90%]",
  "w-full",
  "md:!w-11/12",
  "max-w-fit"
);

const artistName = classNames(
  "font-acromBold",
  "text-accent",
  "text-3xl",
  "md:!text-4xl",
  "text-left"
  // 'mt-10'
);

const artistBio = classNames(
  "text-gray",
  "font-seasonsLight",
  "text-md",
  "mt-2",
  "md:!text-xl"
);

const mainImg = classNames(
  "h-80",
  "md:!h-96",
  "rounded-lg",
  "w-auto",
  "object-cover"
);
const buttonContainer = classNames("pr-0", "lg:!pr-6", "md:!pt-6", "lg:!w-4/6");

export const ArtistPage = ({ artist }) => {


  return (
    <div className={container}>
      <div className={content}>
        <div className={classNames(artistName, "w-full", "mt-8", "md:!mt-10")}>
          {artist.name}
        </div>

        <div className="flex flex-col md:!flex-row-reverse h-full md:!h-3/6 justify-between content-between">
          <div className="h-full w-full md:!w-2/6 lg:!w-2/6">
            <div className="mt-6 md:!mt-0">
              <img src={artist.img} alt="haircut" className={mainImg} />
            </div>
          </div>

          <div className=" w-full md:!w-1/2 lg:!w-4/6 mt-4 mb-4">
            <div className="w-full h-full md:!w-[90%] flex flex-col justify-center">
              <div className={artistBio}>{artist.description}</div>
              <div className={buttonContainer}>
                <Links
                  name="Book consultation"
                  target="_blank"
                  link="https://la-beauty-bureau.square.site/"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 mb-10">
        <ArtistScroll text={artist.scroll} width={artist.scrollWidth} />
      </div>
    </div>
  );
};
