import classNames from "classnames";

export const container = classNames(
  "mt-10",
  "h-full",
  "flex",
  "flex-col",
  "justify-center",
  "md:!justify-center",
  "w-full",
  "bg-black",
  "min-h-[750px]",
  "items-center",
  "text-gray",
  "max-w-full"
);


export const content = classNames(
    'p-4',
    'flex',
    'flex-col',
    'h-full',
    'w-full',
    'md:!w-4/6',
    'justify-center',
    'md:!justify-evenly',
);

export const header = classNames(
    "font-acromBold",
    "text-gray",
    "text-2xl",
    "md:!text-4xl"
  );