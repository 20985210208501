import img1 from "./IMG_0588 Large.jpeg";
import img2 from "./IMG_0617 Large.jpeg";
import img3 from "./IMG_0773 Large.jpeg";
import img4 from "./IMG_5208 Large.jpeg";
import img5 from "./IMG_7345 Large.jpeg";
import img6 from "./haicut.jpeg";


const niginaImages = [img1, img2, img3, img4, img5, img6];
export default niginaImages;
