import img1 from "./IMG_1235.JPG";
import img2 from "./IMG_1259.JPG";
import img3 from "./IMG_1260.JPG";
import img4 from "./IMG.jpeg";
import img5 from "./IMG2.jpeg";



const vatyaImages = [img1, img2, img3, img4, img5];
export default vatyaImages;
