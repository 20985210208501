import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { ArtistCard } from "../ArtistCard";
import { Footer } from "../Footer";
import Dasha from "../../assets/portraits/Dasha.JPG";
import Marina from "../../assets/portraits/Marina.JPG";
import Nigina from "../../assets/portraits/Nigina.JPEG";
// import Nataly from "../../assets/portraits/Nataly.JPEG";
import Vatya from "../../assets/portraits/Vatya.jpeg";
// import Vadim from "../../assets/portraits/Vadim.jpeg";

import { container, header } from "./index";

const content = classNames(
  "p-4",
  "flex",
  "flex-col",
  "h-full",
  "w-[95%]",
  "md:!w-[85%]",
  "lg:!w-[90%]",
  "xl:!w-[90%]",
  "2xl:!w-[70%]",
  "justify-evenly",
  "relative"
);

const imageContainer = classNames(
  "h-[26rem]",
  "md:!h-[34rem]",
  "flex flex-row",
  "mb-8",
  "mt-4",
  "overflow-y-hidden",
  "overflow-x-auto",
  "snap-mandatory snap-x ...",
  "scrollbar-hide"
);
const footerContainer = classNames(
  "h-[20%]",
  "bottom-0 ",
  "left-0 right-0",
  "w-full",
  "mb-4"
);

const arrayArtists = [
  { id: 1, name: "Nigina", position: "HAIR ARTIST", img: Nigina },
  { id: 2, name: "Vatya", position: "ESTETICIAN", img: Vatya },
  // { id: 3, name: "Nataly", position: "HAIR ARTIST", img: Nataly },
  { id: 4, name: "Dasha", position: "NAIL TECHNICIAN", img: Dasha },
  // { id: 5, name: "Marina", position: "NAIL TECHNICIAN", img: Marina },
  // { id: 6, name: "Vadim", position: "HAIR ARTIST", img: Vadim },
];

export const Artists = () => {
  return (
    <div className={classNames(container)}>
      <div className={content}>
        <div className={header}>ARTISTS</div>
        <div className={imageContainer}>
          {arrayArtists.map((artist) => (
            <Link
              to={`/artists/${artist.name}`}
              key={artist.id}
              className="min-w-max"
            >
              <ArtistCard artist={artist} />
            </Link>
          ))}
        </div>
      </div>
      <div className={footerContainer}>
        <Footer />
      </div>
    </div>
  );
};
