import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const mainContainer= classNames(
    'bg-black',
    'w-full',
    'border-b-2',
    'border-gray',
    'grid',
    'place-items-center',
    'h-full'
)

const margins =classNames(
    'mr-4',
    'ml-4',
)
const width = classNames(
    'w-full',
    'h-full',
    'flex',
    'justify-between',
    'items-center',
    'md:!w-[85%]',
    'lg:!w-[90%]',
    'xl:!w-[90%]',
    '2xl:!w-[70%]'
    
  )
const button =classNames(
    'bg-accent',
    'min-w-max',
    'w-28',
    'h-10',
    'md:!w-40',
    'lg:!w-52',
    'lg:!h-12',
    'rounded-md',
    'shadow-md',
    'drop-shadow-lg',
    'text-black',
    'font-seasonsBold',
    'text-md',
    'md:!text-xl'
    
)

const header =classNames(
    'font-acromBold',
    'text-gray',
    'text-xl',
    'md:!text-3xl',
    'lg:!text-4xl'
)

export const Header =()=>{
    return(
        <div className={mainContainer}>
            <div className={width}>
                <div className={margins}>
                    <span className={header}>  <Link to= {`/`}>LA BEAUTY BUREAU</Link></span>
                </div>
                
                <div className={margins}>
                    <button className={button}>
                        <a href="https://la-beauty-bureau.square.site" target="_blank" rel="noreferrer" > 
                            BOOK NOW
                            </a>
                    </button>
                </div>
            </div>
           
            
        </div>
    )
}