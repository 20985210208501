import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {Home} from './pages/Home.js';
import {DynamicArtists} from './pages/DynamicArtists.js';




export const  App = ()=> {
  return (
    <Routes>
      <Route path ='/' element={<Home/>}/>
      <Route path ='/artists/:id' element={<DynamicArtists/>}/>
      <Route path ='*' element={<div>Not found hahaha</div>}/>
    </Routes>
  );
}

export default App;
