import React, { useEffect } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { Header } from "../components/Header";
import { ArtistPage } from "../components/ArtistPage";

import Dasha from "../assets/portraits/Dasha.JPG";
import Marina from "../assets/portraits/Marina.JPG";
import Nigina from "../assets/portraits/Nigina.JPEG";
// import Nataly from "../assets/portraits/Nataly.JPEG";
import Vatya from "../assets/portraits/Vatya.jpeg";
// import Vadim from "../assets/portraits/Vadim.jpeg";

import { ArtistWork } from "../components/ArtistWork";

import dashaImages from "../assets/work/dasha/index.js";
import marinaImages from "../assets/work/marina/index.js";
// import natImages from "../assets/work/nataly/index.js";
import niginaImages from "../assets/work/nigina/index.js";
// import vadimImages from "../assets/work/vadim/index.js";
import vatyaImages from "../assets/work/vatya/index.js";

const DashaDescription =
  "Dasha is a nail technician with more then 6 years experience. Total almond shape nail whispers She is a master  of fancy designs and good vibes Dasha is very skilled in Russian manicure technique";
const NiginaDescription =
  "Nigina is a color and cut specialist Beverly Hills trained; she also went through dozens of classes all over the world to find her own style and perfect. Outcome of her artistry can be icy airtouch or sun kissed nearly there ribbons of balayage keeping hair integrity";
// const NatDescription =
//   "Nataly has been working as a hairstylist back home for years and after moving to Los Angeles found her professional home here at LA Beauty Bureau. She is specializing in short haircuts, airtouch blinding technique and extensions";
const VatyaDescription =
"After earning her dentistry doctorate and working in Russia, Vatya relocated to LA to follow her passion for skincare. She is excited to help you achieve healthier skin and looks forward to your visit";
  // `After getting her doctorate degree in dentistry and working in Russia for some time, Vatya decided she wanted to surround herself in the skin care environment once moved to LA. Ever since, skin care has become her full time passion and She can’t wait to see you soon for your better and healthier skin`;
const MarinaDescription =
  "Marina is LA based nail artist with exceptional skills, European manicure specialist. She does the most detailed French manicure in town and is just so very cute, we can’t";
// const VadimDescription =
  // "Vadim is the only barber of LA beauty Bureau. He is a natural and been cutting hair and beards for about 5 years. His fades are so smooth, you have to see it to believe it";

const dashaScroll = "Almond shape perfection";
const niginaScroll =
  "Perfect balayage" + "\xa0\xa0\xa0\xa0" + "  Modern haircut";
const marinaScroll = "French nail masterpiece";
const vatyaScroll =
  "Glowing skin" + "\xa0\xa0\xa0\xa0" + " Non invasive face lift";
// const vadimScroll = 'modern fade'+ "\xa0\xa0"+ 'beard trim'+"\xa0\xa0"+ 'grey blending';

const arrayArtists = [
  {
    id: 1,
    name: "Nigina",
    position: "HAIR ARTIST",
    img: Nigina,
    description: NiginaDescription,
    link: "#",
    images: niginaImages,
    scroll: niginaScroll,
    scrollWidth: 100,
  },
  {
    id: 2,
    name: "Vatya",
    position: "ESTETICIAN",
    img: Vatya,
    description: VatyaDescription,
    link: "#",
    images: vatyaImages,
    scroll: vatyaScroll,
    scrollWidth: 100,
  },
  // {
  //   id: 3,
  //   name: "Nataly",
  //   position: "HAIR ARTIST",
  //   img: Nataly,
  //   description: NatDescription,
  //   link: "#",
  //   images: natImages,
  //   scroll: niginaScroll,
  //   scrollWidth: 100,
  // },
  {
    id: 4,
    name: "Dasha",
    position: "NAIL TECHNICIAN",
    img: Dasha,
    description: DashaDescription,
    link: "#",
    images: dashaImages,
    scroll: dashaScroll,
    scrollWidth: 75,
  },
  {
    id: 5,
    name: "Marina",
    position: "NAIL TECHNICIAN",
    img: Marina,
    description: MarinaDescription,
    link: "#",
    images: marinaImages,
    scroll: marinaScroll,
    scrollWidth: 100,
  },
  // {
  //   id: 6,
  //   name: "Vadim",
  //   position: "BARBER",
  //   img: Vadim,
  //   description: VadimDescription,
  //   link: "#",
  //   images: vadimImages,
  //   scroll: vadimScroll,
  //   scrollWidth: 100,
  // },
];

const container = classNames("bg-black", "h-screen", "relative", "text-gray");
const headerContainer = classNames(
  "flex",
  "justify-center",
  "h-20",
  "fixed top-0 left-0 right-0",
  "z-50"
);
export const DynamicArtists = () => {
  const { id } = useParams();
  const currentArtist = arrayArtists.find((el) => el.name === id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={container}>
      <div className={headerContainer}>
        <Header />
      </div>
      <div className="h-[90%] mt-20">
        <ArtistPage artist={currentArtist} />
        <ArtistWork
          images={currentArtist.images}
          artistName={currentArtist.name}
        />
      </div>
    </div>
  );
};
