import React from "react";
import classNames from "classnames";

const cardContainer = classNames(
  "h-24",
  "w-40",
  "md:!h-36",
  "md:!w-auto",
  "bg-accent",
  "rounded",
  "flex",
  "flex-col",
  "justify-center",
  "drop-shadow-xl",
  "text-center"
);

const img = classNames(
  "p-4",
  "pt-6",
  "pb-6",
  "object-scale-down",
  "h-28"
);

const brandText = classNames(
  "font-seasonsBold",
  "text-black",
  "text-xl",
  "md:!text-sm",
  "pr-2",
  "pl-2"
);

export const BrandCard = ({ card }) => {
  if (card.length < 20) {
    return (
      <div className={cardContainer}>
        <span className={brandText}>{card}</span>
      </div>
    );
  } else {
    return (
      <div className={cardContainer}>
        <img src={card} className={img} alt="" />
      </div>
    );
  }
};
