import img1 from './IMG_0905.JPG';
import img2 from './IMG_0906.JPG';
import img3 from './IMG_0907.JPG';
import img4 from './IMG_0908.JPG';
import img5 from './IMG_0909.JPG';

const dashaImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
];
export default dashaImages;