import React from "react";
import classNames from "classnames";

const container = classNames(
  "flex",
  "flex-col",
  "h-full",
  "justify-evenly",
  "items-center"
);
const media = classNames(
  "flex",
  "gap-12",
  "font-acromBold",
  "text-sm",
  "md:!text-base"
);

const contacts = classNames("font-acrom", "text-sm");

export const Footer = () => {
  return (
    <div className={container}>
      <div className={media}>
        <span>
          <a href="https://goo.gl/maps/MMrjnkjHhVVLE3P38">Google Map</a>
        </span>
        <span>
          <a href="https://www.yelp.com/biz/la-beauty-bureau-tarzana">Yelp</a>
        </span>
        <span>
          <a href="https://www.instagram.com/labeautybureau/?hl=en">
            Instagram
          </a>
        </span>
      </div>
      <div className={contacts}>
        <a href="https://www.google.com/maps/dir/?api=1&destination=18596+Ventura+Blvd,+Tarzana,+CA,+USA&travelmode=driving">
          18596 Ventura Blvd, Tarzana, CA 91356
        </a>
      </div>

      <div className={classNames(contacts, "flex gap-4")}>
        <span>
          <a href="tel:8186797874">(818)-679-7874</a>
        </span>
        <span>
          <a href="mailto:labeautybureau@gmail.com">labeautybureau@gmail.com</a>
        </span>
      </div>
      {/* <span className="text-xs font-accent"><a href="mailto:labeautybureau@gmail.com">2023 Powered by SarConsulting</a></span> */}
    </div>
  );
};
