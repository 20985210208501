import React from "react";
import classNames from "classnames";
// import { Links } from "../Links";
import { ImWhatsapp } from "react-icons/im";
import { ImYelp } from "react-icons/im";
import { BsGoogle } from "react-icons/bs";

import niginaImages from "../../assets/work/nigina/index.js";
import vatyaImages from "../../assets/work/vatya/index.js";
import marinaImages from "../../assets/work/marina/index.js";
// import vadimImages from "../../assets/work/vadim/index.js";

import { container, content } from "./index";
import { ArtistCard } from "../ArtistCard";

const text = classNames(
  "font-seasons",
  "text-xl",
  "md:!text-3xl",
  "text-right"
);
const connect = classNames(
  "flex",
  "justify-evenly",
  "items-center",
  "mt-8 mb-8",
  "md!:mt-0",
  "md:!mb-0"
);

const testScroll = classNames(
  // "h-[22rem]",
  // "md:!h-[34rem]",
  'h-auto',
  "flex flex-row",
  "mb-8",
  "mt-10",
  "md:!mt-14", 
  "md:!mb-14",
  "overflow-y-hidden",
  "overflow-x-auto",
  "snap-mandatory snap-x ...",
  "scrollbar-hide"
);
const iconClass = classNames("w-12", "h-12", "text-accent");
const freeFont = classNames("font-sans");

export const Services = () => {

  const arrayServices = [
    { id: 1, name: "BALAYAGE - from 450$", position: "", img: niginaImages[3] },
    { id: 2, name: "AIRTOUCH - from 450$", position: "", img: niginaImages[2] },
    { id: 3, name: "HAIRCUT - from 80$", position: "", img: niginaImages[5] },
    // { id: 7, name: "MEN'S HAIRCUT - from 60$", position: "", img: vadimImages[2] },
    { id: 4, name: "SCULPTIRAL LIFTING MASSAGE - from 220$", position:"",  img: vatyaImages[4] },
    { id: 5, name: "PRX-33 - from 220$", position: "", img: vatyaImages[3] },
    { id: 6, name: "GEL MANICURE - from 100$", position:"" , img: marinaImages[1] },
  ];

  return (
    <div className={container}>
      <div className={content}>
        <div className={text}>
          LA Beauty Bureau has the best hair, skin and nail specialists. In the
          rapid whirlpool of life self<span className={freeFont}>-</span>love
          always remains the fundamental basis. LA beauty bureau is a point on
          the map of your life, destination<span className={freeFont}>-</span>
          perfection
        </div>
        <div className={connect}>
          <a href="https://api.whatsapp.com/send?phone=8182841850">
            <ImWhatsapp className={classNames(iconClass, "animate-pulse")} />
          </a>
          <a href="https://www.yelp.com/biz/la-beauty-bureau-tarzana">
            <ImYelp className={iconClass} />
          </a>
          <a href="https://goo.gl/maps/MMrjnkjHhVVLE3P38">
            <BsGoogle className={iconClass} />
          </a>
        </div>
        <div className={testScroll}>
          {arrayServices.map((el, index) => (
            <ArtistCard artist={el} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
