import React from "react";
import classNames from "classnames";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

const buttonClass = classNames(
  "mt-4",
  "lg:!mt-6",
  "rounded-xl",
  "border-2",
  "border-accent",
  "bg-accent", 
  "font-acromBold",
  "text-xl",
  "md:!text-xl",
  "h-16",
  "md:!h-[60px]",
  "lg:!h-[80px]",
  "max-h-28",
  "flex justify-between",
  "items-center",
  "shadow-sm",
  "shadow-accent"
);

export const Links = ({ name = "HELLO", link = "#", target = "_self" }) => {
  const internalLink = (
    <Link to={link} className={classNames(buttonClass)} target={target}>
      <span className="m-4">{name} </span>
      <span className="m-4">
        {" "}
        <ArrowLongRightIcon className="h-12 w-12 text-gray" />{" "}
      </span>
    </Link>
  );
  const externalLink = (
    <div>
      <a href={link} target={target} className={buttonClass}>
        <span className="m-4">{name} </span>
        <span className="m-4">
          {" "}
          <ArrowLongRightIcon className="h-12 w-12 text-gray" />{" "}
        </span>
      </a>
    </div>
  );
  const render = target === "_blank" ? externalLink : internalLink;
  return render;
};
