import km from "./km.png";
import lebel from "./lebel.svg";
// import meridian from './meridian.svg';
import bioline from "./bioline.svg";
import redken from "./redken.png";
import wella from "./wella.png";
// import image from './image.png';
import image from "./image1.jpg";

import prx from "./prx.png";
import hello from "./hello.png";
import nr from "./nr.jpg";
import diva from "./diva.png";

const spectrum = "SPECTRUM NAIL BRAND";


const brands = [
  km,
  lebel,
  redken,
  wella,
  bioline,
  image,
  prx,
  hello,
  nr,
  diva,
  spectrum,
];
export default brands;
