import img1 from './IMG_0910.JPG';
import img2 from './IMG_0911.JPG';
import img3 from './IMG_0912.JPG';
import img4 from './IMG_0913.JPG';
import img5 from './IMG_0914.JPG';
import img6 from './IMG_0915.JPG';


const marinaImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,

];
export default marinaImages;