import React from "react";
import classNames from "classnames";

const card = classNames(
  "snap-start ...",
  "min-w-max",
  "h-[26rem]",
  "md:!h-[34rem]",
  "!rounded-lg",
  "relative",
  "overflow-hidden",
  "mr-6"
);

const textClass = classNames("font-acromBold", "text-gray", "z-20");

const mainImg = classNames(
  "h-[26rem]",
  "md:!h-full",
  "rounded-lg",
  "object-cover",
  "z-10",
  "brightness-75",
  "rounded-lg",
  "transform transition duration-500 hover:scale-110",
  "max-w-xs"
);

export const ArtistCard = ({ artist }) => {
  return (
    <div className={card}>
      <span
        className={classNames(
          textClass,
          "absolute top-8 left-8 text-xl md:!text-2xl"
        )}
      >
        {artist.name}
      </span>
      <img src={artist.img} alt="haircut" className={mainImg} />
      <span
        className={classNames(
          textClass,
          "absolute bottom-8 left-8 text-lg md:!text-xl"
        )}
      >
        {artist.position}
      </span>
    </div>
  );
};
