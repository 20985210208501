import React from "react";
import classNames from 'classnames';

const mainContainer = classNames(
    'bg-black',
    'text-accent',
    // 'w-[100vw]',
    'w-full',
    'overflow-hidden',
    // 'absolute',
    'lg:!bottom-52',
    'left-0',
    'md:!absolute'
    // 'bottom-0'
)
const content = classNames(
    'h-full',
    'w-full',
    'flex',
    'animate-scroll',
    'md:!animate-scrollmd'
)

const element =classNames(
    'flex',
    'justify-center',
    'items-center',
    'text-4xl',
    'md:!text-6xl',
    'w-[33vw]',
    'shrink-0',
    'whitespace-nowrap',
    'font-seasonsBold'
)
export const Scroll=()=>{
    return (
        <>
            <div className={mainContainer}>
                <ul className={content}>
                    <li className={element}>HAIR</li>
                    <li className={element}>NAILS</li>
                    <li className={element}>SKIN</li>
                    <li className={element}>HAIR</li>
                    <li className={element}>NAILS</li>
                    <li className={element}>SKIN</li>
                    <li className={element}>HAIR</li>
                    <li className={element}>NAILS</li>
                    <li className={element}>SKIN</li>
                </ul>
            </div>
            <style jsx='true'>{
            `div{--animation-duration:5s;}
            @media(min-width:768px){
            div{--animation-duration:7s;}
            }
            @media(min-width:1024px){
                div{--animation-duration:10s;}
            }
            `}</style>
        </>
        
    )
}