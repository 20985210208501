import React from "react";
import classNames from "classnames";

const mainContainer = classNames(
  "bg-black",
  "text-accent",
  "w-screen",
  "overflow-hidden"
  // 'absolute',
  // 'rotate-90',
  // 'top-14','mt-14'
  // 'lg:!bottom-52',
  // 'left-0',
  // 'bottom-0'
);
const content = classNames(
  "h-full",
  "w-full",
  "flex",
  "animate-scroll",
  "md:!animate-scrollmd"
);

const element = (width) =>
  classNames(
    "flex",
    "justify-center",
    "items-center",
    "text-lg",
    "md:!text-3xl",
    `w-[${width}vw]`, //
    "shrink-0",
    "whitespace-nowrap",
    "font-seasonsBold"
  );
export const ArtistScroll = ({ text, width }) => {
  return (
    <>
      <div className={mainContainer}>
        <ul className={content}>
          <li className={element(width)}>{text.toUpperCase()}</li>
          <li className={element(width)}>{text.toUpperCase()}</li>
          <li className={element(width)}>{text.toUpperCase()}</li>
          <li className={element(width)}>{text.toUpperCase()}</li>
        </ul>
      </div>
      <style jsx="true">{`
        div {
          --animation-duration: 7s;
        }
        @media (min-width: 768px) {
          div {
            --animation-duration: 10s;
          }
        }
        @media (min-width: 1024px) {
          div {
            --animation-duration: 15s;
          }
        }
      `}</style>
    </>
  );
};
