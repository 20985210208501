import React from "react";
import classNames from "classnames";
import { BrandCard } from "../Brands/BrandCard";
import brands from "../../assets/brandsLogo/index";

import { container, content, header } from "./index";

const brandsContainer = classNames(
  "mt-8",
  "md:!mt-14",
  "grid",
  "grid-cols-2",
  "md:!grid-cols-3",
  "gap-2",
  "md:!gap-y-6"
);

export const Brands = () => {
  return (
    <div className={classNames(container)}>
      <div className={classNames(content)}>
        <div className={classNames(header, "lg:!ml-12")}>OUR BRANDS</div>
        <div className={brandsContainer}>
          {brands.map((brand) => (
            <BrandCard key={brand} card={brand} />
          ))}
        </div>
      </div>
    </div>
  );
};
