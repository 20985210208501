import React from "react";
import { Intro } from "../components/slides/Intro";
import { Artists } from "../components/slides/Artists";
import { Services } from "../components/slides/Services";
import { Header } from "../components/Header";
import classNames from "classnames";
import { Brands } from "../components/slides/Brands";
import { SquareReservation } from "../components/slides/SquareReservation";

const mainContainer = classNames("bg-black", "h-screen", "relative", 'max-h-fit');
const headerContainer = classNames(
  "flex",
  "justify-center",
  "h-20",
  "fixed top-0 left-0 right-0",
  "z-50"
);

export const Home = () => {
  return (
    <div className={mainContainer}>
      <div className={headerContainer}>
        <Header />
      </div>
      <div className="mt-20 h-[90%]">
        <Intro />
        <Services />
        {/* <SquareReservation/> */}
        <Brands />
        <Artists />
      </div>
    </div>
  );
};
